@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  list-style: none;
}

button {
  outline: none;
  border: none;
  user-select: none;
  padding: 9px 30px;
  color: white;
  background-color: #000000;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &[disabled] {
    background-color: gray;
    color: lightgray;
  }
}

input:not([type="radio"]):not([type="file"]) {
  padding: 0 12px;
  width: 300px;
  height: 48px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;

  &:hover {
    border-color: #ff9f0a;
    &[disabled] {
      border-color: #d8d8d8;
    }
  }
  &:focus {
    border-color: #ff9f0a;
  }
}

textarea {
  padding: 12px;
  width: 300px;
  height: 180px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  resize: none;

  &:hover {
    border-color: #ff9f0a;
  }
  &:focus {
    border-color: #ff9f0a;
  }
}

input,
textarea,
select {
  outline: none;
  font-family: "Noto Sana KR", sans-serif;

  &::placeholder {
    color: #d8d8d8;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  cursor: pointer;
}

.MuiPaper-root {
  border: 1px solid rgba(224, 224, 224, 1) !important;
  box-shadow: none !important;
  .MuiPaper-root {
    border: none !important;
  }
}
.underline {
  text-decoration: underline;
}
.clickable {
  cursor: pointer;
}

.wysiwyg-text-style {
  white-space: pre-wrap !important;
  font-family: "Noto Sans KR", sans-serif !important;
  letter-spacing: normal;
  .ql {
    &-align {
      &-center {
        text-align: center;
      }
      &-right {
        text-align: right;
      }
      &-justify {
        text-align: justify;
      }
    }
  }
  p,
  li {
    font-size: 16px !important;
    line-height: 1.63 !important;
  }
  li {
    list-style: inherit;
  }
  h1 {
    font-size: 24px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42 !important;
    letter-spacing: normal;
  }
  h3 {
    font-size: 20px !important;
    font-weight: bold;
    line-height: 1.5 !important;
  }
  img {
    width: 100% !important;
  }
  iframe {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 440px;
    height: 248px;
  }
  blockquote + blockquote {
    margin-top: -5px !important;
  }
  @media screen and (max-width: 1194px) {
    iframe {
      width: 100%;
      height: calc(100vw * 0.5);
    }
  }
}
