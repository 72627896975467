.field {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  
  .field_title {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    min-height: 36px;
    white-space: pre;
    font-weight: bold;
    color: #444;
    line-height: 40px;
  }

  .field_body {
    width: 300px;
    >* {
      margin-right: 20px;
    }
  }
  .field_warning {
    font-size: 14px;
    margin-left: 10px;
    color: red;
  }

  input {
    padding: 0 6px;
    width: 100%;
    height: 36px;
    font-size: 16px;
    border: 1px solid #999;
    border-radius: 3px;
    outline: none;
    box-sizing: border-box;
  }
  textarea {
    padding: 6px;
    width: 100%;
    height: 120px;
    font-size: 16px;
    resize: none;
    border: 1px solid #999;
    border-radius: 3px;
    outline: none;
    box-sizing: border-box;
  }
  button {
    padding: 6px 18px;
    font-weight: bold;
    color: white;
    background-color: #ff9f0a;
    border: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    &:disabled {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  .file_image {
    display: block;
    max-width: 120px;
  }
  .file_button {
    margin-top: 3px;
  }

  .onoff {
    position: relative;
    width: 60px;
    height: 36px;
  }
  .onoff_background {
    display: flex;
    padding: 3px;
    width: 100%;
    background-color: #ccc;
    border-radius: 18px;
    cursor: pointer;
  }
  .onoff_thumb {
    margin: auto 0;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 15px;
  }
  .onoff-on {
    .onoff_background {
      background-color: #ff9f0a;
    }
    .onoff_thumb {
      margin-left: auto;
    }
  }
  .onoff-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
