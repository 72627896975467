.dashboard {
  .dashboard_navbar {
    position: relative;
    padding: 24px 0;
    width: 240px;
    min-width: 240px;
    color: white;
    background-color: #494949;
    border-right: 1px solid #eee;
  }
  .dashboard_navbar-enterprise {
  }
  .dashboard_navbar-school {
  }

  .dashboard_navbar_menu {
    position: relative;
    display: flex;
    padding: 9px 24px;
    transition: 0.1s background-color;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
      background-color: #fff3;
    }
  }
  .dashboard_navbar_menu-selected {
    background-color: #fff3;
    // pointer-events: none;
  }
  .dashboard_navbar_menu_icon {
    margin-right: 12px;
  }
  .dashboard_navbar_menu_title {
  }
}
