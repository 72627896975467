#popup {
  .popup_prompt {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    .popup_input {
      margin: 18px auto 0 auto;
    }
    .popup_input::placeholder {
      color: #d8d8d8;
    }
    .popup_input:hover {
      border-color: #ff9f0a;
    }
    .popup_input:focus {
      border-color: #ff9f0a;
    }
  }
}
