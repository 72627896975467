.swings {
  // .dashboard_content_title span {
  //   font-size: 18px;
  //   font-weight: bold;
  // }
  // .name {
  //   color: #ff9f0a;
  //   text-decoration: underline;
  //   cursor: pointer;
  // }
  .dashboard_content_graph {
    // margin-right: 10px;
    // width: 100%;
    width: 1000px;
    flex-grow: 1;
  }
}
