.add-user {
  h2 {
    margin-bottom: 30px;
  }
  section {
    display: flex;
    height: 40px;
    padding: 0 10px;
    align-items: center;
    margin-bottom: 20px;
    .title {
      width: 120px;
      line-height: 40px;
      font-weight: bold;
    }
    .content {
      width: 300px;
      >* {
        margin-right: 20px;
      }
    }
    .field_warning {
      font-size: 14px;
      margin-left: 10px;
      color: red;
    }
  }
  button {
    margin-top: 10px;
    margin-left: 320px;
  }
}