.dashboard {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 900px;
  height: 100vh;

  .dashboard_header {
    z-index: 1;
  }
  .dashboard_body {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .dashboard_content {
    flex: 1;
    padding: 12px 18px;
  }
  .dashboard_content_title {
    font-size: 24px;
    font-weight: bold;
    span {
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .dashboard_unauth {
    display: flex;
    flex-direction: column;
    margin: 120px auto auto auto;
  }
  .dashboard_unauth_title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .dashboard_unauth_btns {
    display: flex;
    margin: 30px auto 0 auto;
    gap: 12px;
  }
  .dashboard_unauth_btn {
    width: 180px;
    font-size: 18px;
  }
  .dashboard_unauth_btn-login {
    color: #ff9f0a;
    background-color: transparent;
    border: 1px solid #ff9f0a;
  }
}
