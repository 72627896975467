.lessons {
  .dashboard_content_title span {
    font-size: 18px;
    font-weight: bold;
  }
  // .loginId {
  //   text-decoration: underline;
  //   // cursor: pointer;
  // }
  
  .isApprove {
    color: #ff9f0a;
    text-decoration: underline;
    cursor: pointer;
  }
}
