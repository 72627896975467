.swings {
  .dashboard_content_title span {
    font-size: 18px;
    font-weight: bold;
  }
  // .name {
  //   color: #ff9f0a;
  //   text-decoration: underline;
  //   cursor: pointer;
  // }
}
