.login {
  display: flex;
  width: 100%;
  height: 100vh;
  padding-bottom: 30%;
  box-sizing: border-box;

  .login_centered {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 30px;
    border-radius: 6px;
  }

  .login_logo {
    display: flex;

    img {
      width: 270px;
    }
  }
  
  .text-overlay {
    position: absolute;
    margin: 0 0 auto px;
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
  }

  .login_form {
    display: flex;
    flex-direction: column;
    padding: 36px 0;
    gap: 18px;
  }
  .login_field {
  }
  .login_field_title {
    margin-bottom: 6px;
    color: #4d4d4d;
    user-select: none;
  }

  .login_button {
    width: 100%;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
  }

  .login_warning {
    position: absolute;
    bottom: 3px;
    font-size: 12px;
    color: #ff9f0a;
  }
}
