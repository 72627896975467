#popup {
  font-family: "Noto Sans KR", sans-serif;

  .popup_container {
    z-index: 100;
    position: fixed;
    display: flex;
    padding-bottom: 6%;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
  }

  .popup_overlay {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .popup_content {
    position: relative;
    z-index: 1;
    margin: auto;
    padding: 48px 28px;
    color: black;
    background-color: white;
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
  }
  .popup_content_close {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 32px;
    height: 32px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    &:hover {
      background-color: #f8f8f8;
    }
  }
  .popup_content-allowOverflow {
    overflow: visible;
  }

  .popup_title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    white-space: pre;
  }
  .popup_message {
    margin-top: 16px;
    font-size: 16px;
    text-align: center;
    white-space: pre;
    color: #787370;
  }
  .popup_buttons {
    margin-top: 30px;
    display: flex;
    gap: 12px;
  }
  .popup_button {
    margin: 0 auto;
    padding: 16px 24px;
    width: 100%;
    text-align: center;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .popup_button:hover {
    opacity: 0.9;
  }
  .popup_button-cancel {
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    color: #494949;
  }
  .popup_button-confirm {
    color: white;
    background-color: #ff9f0a;
  }
  .popup_button-disabled {
    color: white;
    border-radius: 5px;
    background-color: #999999;
    pointer-events: none;
  }

  .popup_input {
    padding: 12px 12px;
    width: 100%;
    font-size: 18px;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    caret-color: #ff9f0a;
    outline: none;
  }
  .popup_input::placeholder {
    color: #d8d8d8;
  }
  .popup_input:hover {
    border-color: #ff9f0a;
  }
  .popup_input:focus {
    border-color: #ff9f0a;
  }
}

.body-unscrollable {
  height: 100vh;
  overflow-y: hidden;
}
