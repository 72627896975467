.student {
  .student_fields {
    margin-top: 30px;
  }
  .btn_cancel {
    margin-left: 30px;
    color: #ffffff;
    background-color: #504b4b;
  }
  .btn_edit {
    color: #ffffff;
    background-color: #16e238;
  }
}
