.students {
  .dashboard_content_title span {
    font-size: 18px;
    font-weight: bold;
  }
  .loginId {
    color: #ff9f0a;
    text-decoration: underline;
    cursor: pointer;
  }
  .photo {
    height: 36px;
    width: 36px;
    border-radius: 18px;
    object-fit: cover;
  }
  .schools {
    white-space: pre;
  }
}
