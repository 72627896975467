.institution {
  .institution_fields {
    margin-top: 30px;
  }
  .btn_cancel {
    margin-left: 30px;
    color: #ffffff;
    background-color: #504b4b;
  }
  .btn_edit {
    color: #ffffff;
    background-color: #16e238;
  }
  section {
    display: flex;
    height: 40px;
    padding: 0 0px;
    align-items: center;
    margin-bottom: 20px;
    .title {
      width: 120px;
      line-height: 40px;
      font-weight: bold;
    }
    .content {
      width: 300px;
      >* {
        margin-right: 20px;
      }
    }
  }
}
