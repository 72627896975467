.dashboard {
  .dashboard_header {
    display: flex;
    padding: 12px 6% 12px 24px;
    height: 64px;
    border-bottom: 1px solid #eee;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
  }
  .dashboard_header-enterprise {
  }
  .dashboard_header-school {
  }

  .dashboard_header_logo {
    display: flex;
    // height: 115%;
    margin-top: -12px;
    cursor: pointer;
    img {
      height: 132%;
    }
    // span {
    //   margin: 0 0 auto 3px;
    //   line-height: 1;
    //   font-size: 9px;
    //   font-weight: bold;
    //   color: #4d4d4d;
    // }
  }
  .dashboard_header_logo-disable {
    pointer-events: none;
  }

  .dashboard_header_title {
    margin: auto 0 auto 30px;
    font-size: 24px;
    font-weight: bold;
    color: #4d4d4d;
  }

  .dashboard_header_user {
    display: flex;
    margin-left: auto;
    gap: 12px;
  }
  .dashboard_header_user_level {
    display: flex;
    margin: auto 0;
    padding: 3px 9px;
    font-size: 14px;
    border-radius: 6px;
    background-color: #ff9f0a44;
    border: 1px solid #ff9f0a;
  }
  .dashboard_header_user_loginId {
    margin: auto 0;
    font-size: 14px;
    font-weight: bold;
    color: #ff9f0a;
  }
  .dashboard_header_user_menu {
    margin: auto 0;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
}
