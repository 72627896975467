.popup_flash {
  display: flex;
  flex-direction: column;
  min-width: 300px;

  .popup_flash_logo {
    margin: 0 auto 24px auto;
    height: 48px;
  }
}
